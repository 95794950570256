export const pinnedImagesData = [
    {   title: "bottle",
        altText: "a blue water bottle",
        percentFromTop: "16.5%",
        percentFromLeft: "75%",
        smHeight: "75px",
        mdHeight: "100px",
        lgHeight: "120px",
    },  
    {
        title: "record-player",
        altText: "a blue water bottle",
        percentFromTop: "28%",
        percentFromLeft: "60%",
        smHeight: "90px",
        mdHeight: "120px",
        lgHeight: "140px",
    },
    {
        title: "sewing-machine",
        altText: "a red industrial sewing machine",
        percentFromTop: "47%",
        percentFromLeft: "0%",
        smHeight: "404px",
        mdHeight: "511px",
        lgHeight: "621px",
    },    
    {
        title: "fabric",
        altText: "pink fabric",
        percentFromTop: "42%",
        percentFromLeft: "42%",
        smHeight: "100px",
        mdHeight: "125px",
        lgHeight: "155px",
    },
    {
        title: "headphones",
        altText: "yellow headphones",
        percentFromTop: "27%",
        percentFromLeft: "47%",
        smHeight: "90px",
        mdHeight: "115px",
        lgHeight: "140px",
    },
    {
        title: "learningstatement",
        altText: "a teal sign",
        percentFromTop: "16%",
        percentFromLeft: "35%",
        smHeight: "50px",
        mdHeight: "70px",
        lgHeight: "80px",
    },
    {
        title: "laptop",
        altText: "a red industrial sewing machine",
        percentFromTop: "28%",
        percentFromLeft: "30%",
        smHeight: "87px",
        mdHeight: "110px",
        lgHeight: "135px"
    },
    {
        title: "newspaper",
        altText: "an orange newspaper",
        percentFromTop: "33%",
        percentFromLeft: "75%",
        smHeight: "50px",
        mdHeight: "70px",
        lgHeight: "80px",
    },
    {
        title: "games",
        altText: "a brown pair of die on a brown box",
        percentFromTop: "45%",
        percentFromLeft: "65%",
        smHeight: "65px",
        mdHeight: "75px",
        lgHeight: "92px"
    },
    {
        title: "printer",
        altText: "a red industrial sewing machine",
        percentFromTop: "52.5%",
        percentFromLeft: "75%",
        smHeight: "105px",
        mdHeight: "125px",
        lgHeight: "150px",
    }
]
